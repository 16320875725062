import { Form } from "react-bootstrap";
import { TextDocumentField } from "src/types";
import { FieldValueInputProps } from "./FieldValue";
import { FieldValueInputWrapper } from "./FieldValueInputWrapper";

export const FieldValueInputText: React.FC<
  FieldValueInputProps & { field: TextDocumentField }
> = ({ field, onChange, unused, hideLabel, ...rest }) => {
  return (
    <FieldValueInputWrapper
      unused={unused}
      hideLabel={hideLabel}
      field={field}
      {...rest}
    >
      <Form.Control
        {...rest}
        onChange={(e) => onChange?.(e.target.value)}
        isInvalid={false}
      />
    </FieldValueInputWrapper>
  );
};

export const FieldValueText: React.FC<
  FieldValueInputProps & { field: TextDocumentField }
> = ({ value }) => {
  return value;
};
