import { StepType, TourProvider } from "@reactour/tour";
import { Button } from "@ream/ui";
import clsx from "clsx";
import { noop } from "lodash-es";
import { ArrowLeft, ArrowRight, XIcon } from "lucide-react";
import { ComponentProps, PropsWithChildren } from "react";

const Close: React.FC<ComponentProps<any>> = ({
  onClick,
  styles,
  disabled,
}) => {
  return (
    <Button
      StartIcon={XIcon}
      variant="icon"
      style={{ ...styles, position: "absolute", top: 8, right: 8 }}
      disabled={disabled}
      size="sm"
      onClick={onClick}
      iconOnly
    />
  );
};

const Arrow: React.FC<ComponentProps<any>> = ({
  disabled,
  styles,
  inverted,
}) => {
  const NavIcon = inverted ? ArrowRight : ArrowLeft;
  return (
    <NavIcon
      style={styles}
      size={24}
      className={clsx("text-reset", disabled && "opacity-25")}
    />
  );
};

const NextButton = (props) => {
  const isLastStep = props.currentStep === props.steps.length - 1;

  return !isLastStep ? (
    <props.Button />
  ) : (
    <Button onClick={() => props.setIsOpen(false)}>Done!</Button>
  );
};

const popoverStyles = {
  "--reactour-accent": "var(--primary)",
  borderRadius: "var(--border-radius)",
  background: "var(--surface-2)",
  border: "1px solid var(--border-color)",
  marginTop: "1em",
};

const maskAreaStyles = (base) => ({ ...base, rx: "var(--border-radius)" });

export const BaseTour: React.FC<{ steps: StepType[] } & PropsWithChildren> = ({
  steps,
  children,
}) => {
  return (
    <TourProvider
      styles={{
        popover: (base) => ({
          ...base,
          ...popoverStyles,
        }),
        maskArea: maskAreaStyles,
      }}
      showBadge={false}
      onClickMask={noop}
      nextButton={NextButton}
      components={{
        Close,
        Arrow,
      }}
      steps={steps}
    >
      {children}
    </TourProvider>
  );
};
