import { UsersIcon } from "lucide-react";
import React from "react";
import { ChooseButton } from "./ChooseButton";

export const ChoosePeoplePlaceholder = React.forwardRef<
  HTMLButtonElement,
  React.ComponentProps<"button"> & {
    label?: string;
    help?: string;
  }
>(({ label, help, ...rest }, ref) => {
  return (
    <ChooseButton
      {...rest}
      icon={UsersIcon}
      label={label ?? "Select Reviewers"}
      help={help}
      ref={ref}
    />
  );
});
ChoosePeoplePlaceholder.displayName = "ChoosePeoplePlaceholder";
