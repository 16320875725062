import { Icon, parseDateStrict } from "@ream/ui";
import { CalendarIcon } from "lucide-react";
import React from "react";
import { FormControl, InputGroup } from "react-bootstrap";
import DatePicker, { ReactDatePickerProps } from "react-datepicker";
import { ZonedDateTimeString } from "src/types";

const CustomInput = React.forwardRef(
  (inputProps: any, ref: React.Ref<HTMLDivElement>) => {
    return (
      <InputGroup ref={ref}>
        <InputGroup.Text>
          <Icon icon={CalendarIcon} color="muted" />
        </InputGroup.Text>

        <FormControl {...inputProps} />
      </InputGroup>
    );
  },
);

CustomInput.displayName = "DateInput";

export const DateInput: React.FC<
  ReactDatePickerProps & {
    value: Date | ZonedDateTimeString;
    onChange: (date: ZonedDateTimeString) => void;
  }
> = ({ onChange, value, ...rest }) => {
  const val = value ? parseDateStrict(value) : undefined;

  return (
    <DatePicker
      {...rest}
      selected={val}
      onChange={(date: Date) => onChange(date?.toISOString())}
      customInput={<CustomInput />}
    />
  );
};
