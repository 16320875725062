import { EmptyCard } from "@ream/ui";
import { Stack } from "react-bootstrap";
import { FieldValueInput } from "src/components/documents/fields/FieldValue";
import { useBlankableField } from "src/components/form/BlankableField";
import {
  DocumentField,
  DocumentFieldValue,
  DocumentFileSchema,
  FieldName,
} from "src/types";
import { fieldIsUsed } from "src/util/data/documentFields";
import { useSortedFieldEntries } from "../context/hooks/useSortedFieldEntries";
import { ValuesPanelProps } from "./ValuesPanel";

export const FormValuesPanel: React.FC<
  ValuesPanelProps & {
    html: string | null;
    schema: DocumentFileSchema | null;
    fields: Record<FieldName, DocumentField>;
    fieldValuesPrefix?: string;
    fieldValuesSuffix?: string;
  }
> = ({
  readOnly = false,
  onInsert,
  cards = true,
  popupContainer,
  html,
  schema,
  fields,
  fieldValuesPrefix,
  fieldValuesSuffix,
}) => {
  const sortedFieldEntries = useSortedFieldEntries(fields);

  return (
    <Stack gap={2}>
      {sortedFieldEntries.length === 0 ? (
        <EmptyCard emptyLabel="There are no values for this document." />
      ) : (
        sortedFieldEntries.map(([name, f]) => {
          return (
            <FormFieldValueCard
              key={name}
              fieldName={name as FieldName}
              field={f}
              valuePathPrefix={fieldValuesPrefix}
              valuePathSuffix={fieldValuesSuffix}
              readOnly={readOnly}
              onInsert={onInsert}
              cards={cards}
              html={html}
              schema={schema}
              popupContainer={popupContainer}
            />
          );
        })
      )}
    </Stack>
  );
};

type FormFieldValueCardProps = ValuesPanelProps & {
  html: string | null;
  schema: DocumentFileSchema | null;
  valuePathPrefix?: string;
  valuePathSuffix?: string;
  fieldName: FieldName;
  field: DocumentField;
};

const FormFieldValueCard: React.FC<FormFieldValueCardProps> = ({
  fieldName,
  field,
  valuePathPrefix,
  valuePathSuffix,
  readOnly,
  html,
  schema,
  popupContainer,
}) => {
  const valueKey = [valuePathPrefix, "fieldValues", valuePathSuffix, fieldName]
    .filter((x) => x)
    .join(".");

  const valField = useBlankableField<DocumentFieldValue>(valueKey);
  const isUsed = fieldIsUsed(fieldName, field, html, schema);

  return (
    <FieldValueInput
      name={fieldName}
      onChange={valField.input.onChange}
      value={valField.input.value}
      field={field}
      disabled={readOnly}
      unused={!isUsed}
      popupContainer={popupContainer}
    />
  );
};
