import { Alert, BaseModal, Button, LoadingCentered } from "@ream/ui";
import React, { useMemo, useRef } from "react";
import { Modal } from "react-bootstrap";
import { Form } from "react-final-form";
import { PacketFields } from "src/components/documents/PacketFields";
import { FormSubmitButton } from "src/components/form/FormSubmitButton";
import { Packet, PacketId } from "src/types";
import { useMutatorError } from "src/util/api/apiError";
import {
  useCreatePacketFromTemplate,
  usePacket,
} from "src/util/api/packetsApi";
import { AppRoutes } from "src/util/appRoutes";
import { isThinPacket } from "src/util/models/packets";
import { useRedirect } from "src/util/redirect";

type Props = {
  templateId: PacketId;
  trigger: JSX.Element;
  autoNavigate?: boolean;
};

export const TemplateToDocumentModal: React.FC<Props> = ({
  templateId,
  trigger,
  autoNavigate = true,
}) => {
  const { data: { packet: template } = { packet: null }, query } = usePacket({
    id: templateId,
  });

  const convert = useCreatePacketFromTemplate();
  const error = useMutatorError(convert, "Something went wrong");
  const redirect = useRedirect();
  const ref = useRef<HTMLDivElement>(null);

  const documents = useMemo(
    () =>
      template?.documents.reduce((obj, d) => {
        obj[d.publicUid] ||= {};
        obj[d.publicUid].title = d.title;

        obj[d.publicUid].fieldValues = {};
        return obj;
      }, {}),
    [template],
  );

  if (query.isLoading) {
    return null;
  }

  return (
    <BaseModal
      size="lg"
      trigger={trigger}
      title={
        template
          ? `Create Document from ${template.title}`
          : "Loading Template..."
      }
      dismissable
    >
      {({ handleClose }) => {
        const onSubmit = async (formData) => {
          if (!template) {
            return;
          }

          if (isThinPacket(template)) {
            Object.keys(formData.documents).forEach(
              (k) => (formData.documents[k].title = formData.title),
            );
          }

          await convert.mutateAsync(
            {
              templateId: template.publicUid,
              title: formData.title,
              documents: formData.documents,
            },
            {
              onSuccess: (res) => {
                const packet: Packet | undefined = res.packet;
                if (autoNavigate && packet?.publicUid) {
                  redirect(
                    AppRoutes.document({ id: packet.documents[0].publicUid }),
                  );
                }
              },
            },
          );
        };

        return template ? (
          <>
            <Form
              keepDirtyOnReinitialize
              onSubmit={onSubmit}
              initialValues={{
                title: `Clone of ${template.title}`,
                documents,
              }}
              render={({ handleSubmit, submitting }) => (
                <form onSubmit={handleSubmit}>
                  <Modal.Body ref={ref}>
                    {error && <Alert variant="danger">{error}</Alert>}

                    <PacketFields packet={template} popupContainer={ref} />
                  </Modal.Body>

                  <Modal.Footer>
                    <Button
                      onClick={handleClose}
                      disabled={submitting}
                      variant="outline-secondary"
                    >
                      Cancel
                    </Button>
                    <FormSubmitButton>Create Document</FormSubmitButton>
                  </Modal.Footer>
                </form>
              )}
            />
          </>
        ) : (
          <LoadingCentered />
        );
      }}
    </BaseModal>
  );
};
