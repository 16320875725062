import { StepType } from "@reactour/tour";
import { BaseTour } from "src/components/tours/BaseTour";
import { TourStepContent } from "src/components/tours/TourStepContent";
import { useAuth } from "src/state/AuthContext";
import { isContactAccount } from "src/util/permissions";

const TOUR_STEPS: StepType[] = [
  {
    selector: "#documents_title",
    content: () => <TourStepContent tour="ftue" step="documents" />,
  },
  {
    selector: "#create_document_button",
    content: () => (
      <TourStepContent tour="ftue" step="documents_and_templates" />
    ),
  },
  {
    selector: "#negotiations_nav_item",
    highlightedSelectors: [
      "#negotiations_nav_item",
      "#reviews_nav_item",
      "#signatures_nav_item",
    ],
    content: () => <TourStepContent tour="ftue" step="reviews" />,
  },
  {
    selector: "#reviews_nav_item",
    content: () => <TourStepContent tour="ftue" step="approvals" />,
  },
  {
    selector: "#negotiations_nav_item",
    content: () => <TourStepContent tour="ftue" step="negotiations" />,
  },
  {
    selector: "#signatures_nav_item",
    content: () => <TourStepContent tour="ftue" step="signatures" />,
  },
  {
    selector: ".logotype",
    content: () => <TourStepContent tour="ftue" step="done" />,
  },
];

export const FtueTour = ({ children }) => {
  const { user } = useAuth();

  if (user && isContactAccount(user)) {
    return <>{children}</>;
  }

  return <BaseTour steps={TOUR_STEPS}>{children}</BaseTour>;
};
