import { Button } from "@ream/ui";
import { LucideIcon } from "lucide-react";
import React from "react";

type ChooseButtonProps = React.ComponentProps<"button"> & {
  icon: LucideIcon;
  label: string;
  help?: string;
};

export const ChooseButton = React.forwardRef<
  HTMLButtonElement,
  ChooseButtonProps
>((props, ref) => {
  const { icon, label, help, ...rest } = props;
  return (
    <Button
      {...rest}
      StartIcon={icon}
      iconColor="muted"
      type="button"
      variant="choose-entity"
      className="py-3 text-muted"
      ref={ref}
    >
      {label}

      {Boolean(help) && (
        <p className="mb-0">
          <span className="small text-muted">{help}</span>
        </p>
      )}
    </Button>
  );
});

ChooseButton.displayName = "ChooseButton";
