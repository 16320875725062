"use client";

import { useTour } from "@reactour/tour";
import { ActionCard, Button, IconText, formatDate } from "@ream/ui";
import { Color } from "@ream/ui/src/types";
import { ClockIcon, HelpCircleIcon } from "lucide-react";
import { NextPage } from "next";
import { useEffect, useState } from "react";
import { Stack } from "react-bootstrap";
import { FeatureGate } from "src/components/FeatureGate";
import { withPageGate } from "src/components/PageGate";
import { PageHeader } from "src/components/PageHeader";
import { AppPageLayout } from "src/components/layout/AppPageLayout";
import { SidebarPortal } from "src/components/layout/nav/SidebarNav";
import { CreateDocumentPopover } from "src/components/modals/CreateDocumentPopover";
import { FtueModal } from "src/components/modals/FtueModal";
import { TemplateToDocumentModal } from "src/components/modals/TemplateToDocumentModal";
import { DocumentTable } from "src/components/table/DocumentTable/DocumentTable";
import { FtueTour } from "src/components/tours/FtueTour";
import { useAuth } from "src/state/AuthContext";
import { useTranslations } from "src/state/TranslationsContext";
import { PacketSummary } from "src/types";
import { useFeatureEnabled } from "src/util/api/features";
import { usePackets } from "src/util/api/packetsApi";
import { AppRoutes } from "src/util/appRoutes";
import Feature from "src/util/feature";
import { isEditorDocument } from "src/util/models/documents";
import { packetIconAndColor } from "src/util/models/packets";
import { isContactAccount } from "src/util/permissions";
import { useRedirect } from "src/util/redirect";

const quickstartColors: Color[] = [
  "blue",
  "green",
  "yellow",
  "orange",
  "purple",
];

const QuickstartCard: React.FC<{
  packet: PacketSummary;
  color: Color;
  style?: React.CSSProperties;
}> = ({ packet, color, style }) => {
  const lastUsedDate = packet.lastUsedAsTemplateAt
    ? formatDate(packet.lastUsedAsTemplateAt)
    : formatDate(packet.createdAt);

  const [icon] = packetIconAndColor(packet);

  return (
    <TemplateToDocumentModal
      templateId={packet.publicUid}
      trigger={
        <ActionCard
          style={style}
          title={packet.title}
          subtitle={`Last Used: ${lastUsedDate}`}
          icon={icon}
          color={color}
        />
      }
    />
  );
};

const FtueButton = () => {
  const { user } = useAuth();

  const [showFtueModal, setShowFtueModal] = useState(false);
  const { setIsOpen } = useTour();

  if (user && isContactAccount(user)) {
    return null;
  }

  return (
    <>
      <Button
        StartIcon={HelpCircleIcon}
        block
        variant="outline-primary"
        onClick={() => {
          setShowFtueModal(true);
        }}
      >
        Show welcome tour
      </Button>

      <FtueModal
        show={showFtueModal || (user && !user.ftueComplete)}
        onClose={() => setShowFtueModal(false)}
        onConfirm={() => setIsOpen(true)}
      />
    </>
  );
};

// Client Request
const DashboardPage: NextPage = () => {
  const redirect = useRedirect();
  const { t } = useTranslations();
  const { user } = useAuth();

  const quickstartEnabled = useFeatureEnabled(Feature.QUICKSTART_TEMPLATES);

  useEffect(() => {
    if (user?.role == "contact") {
      redirect(AppRoutes.negotiations());
    }
  }, [user?.role, redirect]);

  const { data: { packets } = { packets: [] } } = usePackets(
    {
      templates: true,
      top: true,
      per: 4,
    },
    {
      enabled: quickstartEnabled,
    },
  );

  return (
    <AppPageLayout title={t("client.index.title")}>
      <FtueTour>
        <PageHeader title={t("client.index.title")} id="documents_title">
          <CreateDocumentPopover
            template={false}
            trigger={
              <Button id="create_document_button" data-test="dashboard-create-document-button">
                {t("client.index.create_document")}
              </Button>
            }
            onCreate={(doc) =>
              redirect(
                isEditorDocument(doc)
                  ? AppRoutes.documentEditor({ id: doc.publicUid })
                  : AppRoutes.document({ id: doc.publicUid }),
              )
            }
          />
        </PageHeader>

        {quickstartEnabled && packets && packets.length > 0 && (
          <div className="mb-5 mw-container-lg">
            <IconText
              as="h6"
              className="mb-3 fw-semibold text-muted"
              icon={ClockIcon}
            >
              Recently Used Templates
            </IconText>

            <Stack direction="horizontal" gap={3}>
              {packets.map((d, i) => (
                <QuickstartCard
                  key={d.publicUid}
                  packet={d}
                  color={quickstartColors[i]}
                  style={{ alignSelf: "stretch", flex: 1, maxWidth: "25%" }}
                />
              ))}
            </Stack>
          </div>
        )}

        <DocumentTable />

        <SidebarPortal>
          <FeatureGate feature={Feature.FTUE_TOUR}>
            <FtueButton />
          </FeatureGate>
        </SidebarPortal>
      </FtueTour>
    </AppPageLayout>
  );
};

export default withPageGate(DashboardPage);
