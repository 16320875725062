import { RefObject } from "react";
import { DocumentField } from "src/types";
import { exhaustiveGuard } from "src/util/utils";
import { FieldValueDate, FieldValueInputDate } from "./FieldValueDate";
import { FieldValueInputPerson, FieldValuePerson } from "./FieldValuePerson";
import { FieldValueInputText, FieldValueText } from "./FieldValueText";

export type FieldValueProps = {
  name: string;
  field: DocumentField;
  value: any;
  unused?: boolean;
};

export type FieldValueInputProps = {
  name: string;
  field: DocumentField;
  value: any;
  disabled?: boolean;
  onChange?: (value: any) => void;
  hideLabel?: boolean;
  unused?: boolean;
  popupContainer?: RefObject<HTMLElement>;
};

export const FieldValue: React.FC<FieldValueInputProps> = ({
  field,
  ...rest
}) => {
  const type = field.type;

  if (!rest.value) {
    return <span className="text-muted fst-italic">No Value</span>;
  }

  switch (type) {
    case "text":
      return <FieldValueText field={field} {...rest} />;
    case "date":
      return <FieldValueDate field={field} {...rest} />;
    case "person":
      return <FieldValuePerson field={field} {...rest} />;
    default:
      return exhaustiveGuard(type);
  }
};

export const FieldValueInput: React.FC<FieldValueInputProps> = ({
  field,
  ...rest
}) => {
  const type = field.type;
  switch (type) {
    case "text":
      return <FieldValueInputText field={field} {...rest} />;
    case "date":
      return <FieldValueInputDate field={field} {...rest} />;
    case "person":
      return <FieldValueInputPerson field={field} {...rest} />;
    default:
      return exhaustiveGuard(type);
  }
};
