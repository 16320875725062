import { useTranslations } from "src/state/TranslationsContext";

export const TourStepContent: React.FC<{
  tour: string;
  step: string;
  showTitle?: boolean;
}> = ({ tour, showTitle = true, step }) => {
  const { t } = useTranslations();

  return (
    <>
      {showTitle && (
        <h6>
          <strong>{t(`client.tours.${tour}.${step}_title`)}</strong>
        </h6>
      )}
      <p>{t(`client.tours.${tour}.${step}`)}</p>
    </>
  );
};
