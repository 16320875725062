import { Icon } from "@ream/ui";
import clsx from "clsx";
import { PlusIcon } from "lucide-react";
import React from "react";
import { Card, ListGroup, OverlayTriggerProps } from "react-bootstrap";
import { Agentable, AgentableId } from "src/types";

import { ChoosePeoplePlaceholder } from "src/components/form/buttons/ChoosePeoplePlaceholder";
import { ManagePeoplePopover } from "src/components/modals/ManagePeoplePopover";
import { PeopleListItem } from "src/components/PeopleListItem";
import { useOrgPeople } from "src/util/api/orgsApi";

type ChoosePeopleBaseProps = {
  label: string;
  help?: string;
  onlyInternal?: boolean;
  onlyExternal?: boolean;
  overlayProps?: Partial<Omit<OverlayTriggerProps, "children">>;
  disabled?: boolean;
  width?: number;
  allowEdit?: boolean;
  readOnly?: boolean;
  className?: string;
};

type MultiplePeopleProps = {
  onChange: (val: string[] | undefined | null) => void;
  value: string[] | undefined;
  multiple: true;
};

type SinglePersonProps = {
  onChange: (val: string | undefined | null) => void;
  value: string | undefined;
  multiple?: false;
};

type ChoosePeopleInputProps = ChoosePeopleBaseProps &
  (MultiplePeopleProps | SinglePersonProps);

export const ChoosePeopleInput: React.FC<ChoosePeopleInputProps> = ({
  label,
  help,
  value,
  multiple,
  overlayProps,
  onlyInternal,
  onlyExternal,
  disabled,
  width,
  allowEdit,
  readOnly,
  onChange,
  className,
}) => {
  const valueIds = (value ? [value].flat() : []) as AgentableId[];
  const { data: people, isLoading } = useOrgPeople(valueIds);

  const handleChange = (
    agents: Agentable[],
    _added: string[],
    _removed: string[],
  ) => {
    const vals = agents.map((v) => v.publicUid);

    if (multiple) {
      onChange(vals);
    } else {
      onChange(vals.length > 0 ? vals[0] : null);
    }
  };

  // transform value
  let val = value;

  if (!multiple) {
    val = val ? [val as string] : [];
  } else {
    val = Array.isArray(val) ? val : [];
  }

  const agentsVal: Agentable[] = (val as string[])
    .map((id) => people.find((p) => p.publicUid === id))
    .filter((x) => x) as Agentable[];

  const handleAdd = (addedId: string, person: Agentable) => {
    const newValues = [...agentsVal];
    newValues.push(person);
    handleChange(newValues, [addedId], []);
  };

  const handleRemove = (removedId: string) => {
    const newValues = agentsVal.filter((p) => p.publicUid !== removedId);
    handleChange(newValues, [], [removedId]);
  };

  const children = (
    <>
      {(multiple || agentsVal.length === 0) && (
        <ManagePeoplePopover
          selectedIds={agentsVal.map((v) => v.publicUid)}
          trigger={
            agentsVal.length === 0 ? (
              <ChoosePeoplePlaceholder
                label={readOnly ? `No ${label}` : `Select ${label}`}
                help={help}
              />
            ) : (
              <PeopleHeader label={label} />
            )
          }
          overlayProps={overlayProps}
          onAdd={handleAdd}
          onRemove={handleRemove}
          allowCreate={false}
          onlyInternal={onlyInternal}
          onlyExternal={onlyExternal}
          multiple
          width={width}
          disabled={disabled || readOnly}
          disableWhileLoading={false}
          loading={isLoading}
        />
      )}
      {agentsVal.length > 0 && (
        <ListGroup variant="flush">
          {agentsVal.map((p) => (
            <PeopleListItem
              person={p}
              allowEdit={allowEdit}
              key={p.publicUid}
              onRemove={readOnly ? undefined : handleRemove}
              isRemoving={false}
              disabled={disabled}
              hideCompany={false}
              hideEmail={false}
            />
          ))}
        </ListGroup>
      )}
    </>
  );

  return agentsVal.length === 0 ? (
    <div className={className}>{children}</div>
  ) : (
    <Card className={className}>{children}</Card>
  );
};

type PeopleHeaderProps = {
  label: string;
  help?: string;
  disabled?: boolean;
  onClick?: () => void;
};

const PeopleHeader = React.forwardRef<HTMLDivElement, PeopleHeaderProps>(
  ({ label, help, onClick, ...rest }, ref) => {
    return (
      <Card.Header
        {...rest}
        className={clsx(
          "simple_card_header card-action d-flex flex-row align-items-center",
          rest.disabled && "disabled",
        )}
        ref={ref}
        onClick={rest.disabled ? undefined : onClick}
      >
        <div>
          <Card.Title>{label}</Card.Title>
          {Boolean(help) && <p className="small text-muted mb-0">{help}</p>}
        </div>
        <div className="flex-grow" />
        <div>
          <Icon icon={PlusIcon} className="text-muted" />
        </div>
      </Card.Header>
    );
  },
);

PeopleHeader.displayName = "PeopleHeader";
