import { formatDate } from "@ream/ui";
import { Form } from "react-bootstrap";
import { DateInput } from "src/components/form/inputs/DateInput";
import { DateDocumentField } from "src/types";
import { FieldValueInputProps } from "./FieldValue";
import { FieldValueInputWrapper } from "./FieldValueInputWrapper";

export const FieldValueInputDate: React.FC<
  FieldValueInputProps & { field: DateDocumentField }
> = ({ field, unused, hideLabel, ...rest }) => {
  return (
    <FieldValueInputWrapper
      unused={unused}
      hideLabel={hideLabel}
      field={field}
      {...rest}
    >
      <Form.Control as={DateInput} {...rest} />
    </FieldValueInputWrapper>
  );
};

export const FieldValueDate: React.FC<
  FieldValueInputProps & { field: DateDocumentField }
> = ({ value }) => {
  return <>{formatDate(value)}</>;
};
