import { Field, UseFieldConfig, useField } from "react-final-form";

// Setting parse to an identity function to allow blank values that
// don't get cleared on form submit.
//
// https://final-form.org/docs/react-final-form/types/FieldProps#parse
export const BlankableField = (props) => <Field parse={(v) => v} {...props} />;

export const useBlankableField = <
  FieldValue = any,
  T extends HTMLElement = HTMLElement,
  InputValue = FieldValue,
>(
  name: string,
  config?: UseFieldConfig<FieldValue, InputValue>,
) =>
  useField<FieldValue, T, InputValue>(name, {
    parse: (v) => v as any,
    ...config,
  });
