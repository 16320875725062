import clsx from "clsx";
import { noop } from "lodash-es";
import { ChoosePeopleInput } from "src/components/form/inputs/ChoosePeopleInput";
import { PersonDocumentField } from "src/types";
import { FieldValueInputProps, FieldValueProps } from "./FieldValue";
import { FieldValueInputWrapper } from "./FieldValueInputWrapper";

export const FieldValueInputPerson: React.FC<
  FieldValueInputProps & { field: PersonDocumentField; className?: string }
> = ({ field, unused, hideLabel, onChange, className, ...rest }) => {
  return (
    <FieldValueInputWrapper
      unused={unused}
      hideLabel={hideLabel}
      field={field}
      {...rest}
    >
      <ChoosePeopleInput
        {...rest}
        onChange={onChange ?? noop}
        label={field.label}
        multiple={false}
        allowEdit={true}
        className={clsx(className, "flat")}
      />
    </FieldValueInputWrapper>
  );
};

export const FieldValuePerson: React.FC<
  FieldValueProps & { field: PersonDocumentField; className?: string }
> = ({ field, ...rest }) => {
  return (
    <ChoosePeopleInput
      {...rest}
      onChange={noop}
      label={field.label}
      multiple={false}
      className={clsx(rest.className, "flat")}
      readOnly={true}
    />
  );
};
