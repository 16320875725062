import { TooltipIcon } from "@ream/ui";
import { AlertTriangleIcon, CircleSlashIcon } from "lucide-react";
import { FormLabel } from "react-bootstrap";
import { DocumentField } from "src/types";

type FieldValueInputLabelProps = {
  field: DocumentField;
  hasPlaceholder?: boolean;
  unused?: boolean;
};

export const FieldValueInputLabel: React.FC<FieldValueInputLabelProps> = ({
  field,
  hasPlaceholder,
  unused,
}) => {
  return (
    <FormLabel className="d-flex flex-row align-items-center gap-2">
      {field.label}

      {hasPlaceholder && (
        <TooltipIcon
          title="This field has a placeholder value in the document"
          icon={AlertTriangleIcon}
          color="warning"
        />
      )}

      {unused && (
        <TooltipIcon
          icon={CircleSlashIcon}
          color="warning"
          title="This field is unused"
        />
      )}
    </FormLabel>
  );
};
