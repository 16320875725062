import { LoadingCentered } from "@ream/ui";
import React, { ReactNode, useEffect } from "react";
import { useFeature } from "src/util/api/features";
import { AppRoutes } from "src/util/appRoutes";
import Feature from "src/util/feature";
import { useRedirect } from "src/util/redirect";

export const FeatureGate: React.FC<{
  children: ReactNode;
  feature: Feature;
  showLoading?: boolean;
  redirectTo?: string;
  doRedirect?: boolean;
}> = ({
  children,
  feature: featureName,
  redirectTo = AppRoutes.root(),
  showLoading = true,
  doRedirect = true,
}) => {
  const { enabled, isLoading } = useFeature(featureName);
  const redirect = useRedirect();

  useEffect(() => {
    if (isLoading) {
      return;
    }

    if (!isLoading && !enabled && doRedirect) {
      redirect(redirectTo);
    }
  }, [isLoading, enabled]);

  return isLoading ? (
    <> {showLoading && <LoadingCentered />} </>
  ) : (
    <>{enabled ? children : null}</>
  );
};
