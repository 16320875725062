import React, { RefObject } from "react";
import { Card, Stack } from "react-bootstrap";
import { Field } from "react-final-form";
import { TextField } from "src/components/form/TextField";
import { DocumentFileSchema, DocumentId, Fields } from "src/types";
import { FormValuesPanel } from "../editor/sidebar/FormValuesPanel";

type DocumentFieldsProps = {
  docHtml: string | null;
  docSchema: DocumentFileSchema | null;
  docId: DocumentId;
  fields: Fields;
  noTitle?: boolean;
  popupContainer?: RefObject<HTMLElement>;
};

export const DocumentFields: React.FC<DocumentFieldsProps> = ({
  docHtml,
  docSchema,
  docId,
  fields,
  noTitle,
  popupContainer,
}) => {
  return (
    <Stack gap={2}>
      {!noTitle && (
        <Field
          label="Title"
          required
          name={`documents.${docId}.title`}
          component={TextField}
        />
      )}

      <Card>
        <Card.Header>
          <Card.Title>Values</Card.Title>
        </Card.Header>

        <Card.Body>
          <Stack gap={3}>
            <FormValuesPanel
              cards={false}
              popupContainer={popupContainer}
              html={docHtml}
              schema={docSchema}
              fields={fields}
              fieldValuesPrefix={`documents.${docId}`}
            />
          </Stack>
        </Card.Body>
      </Card>
    </Stack>
  );
};
