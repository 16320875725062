import React, { RefObject } from "react";
import { Stack } from "react-bootstrap";
import { Field } from "react-final-form";
import { DocumentFields } from "src/components/documents/DocumentFields";
import { TextField } from "src/components/form/TextField";
import { Packet } from "src/types";
import { isThinPacket } from "src/util/models/packets";
import { required } from "src/util/validators";

type PacketFieldsProps = {
  packet: Packet;
  popupContainer?: RefObject<HTMLElement>;
};

export const PacketFields: React.FC<PacketFieldsProps> = ({
  packet,
  popupContainer,
}) => {
  return (
    <Stack gap={4}>
      {
        <Field
          label="Title"
          required
          name="title"
          component={TextField}
          validate={required()}
          autoFocus
        />
      }

      {packet.documents.map((d) => {
        return (
          <div key={d.publicUid}>
            {!isThinPacket(packet) && <h5>{d.title}</h5>}
            <DocumentFields
              docHtml={d.html}
              docSchema={d.documentFileSchema}
              docId={d.publicUid}
              fields={d.fields}
              popupContainer={popupContainer}
              noTitle={isThinPacket(packet)}
            />
          </div>
        );
      })}
    </Stack>
  );
};
