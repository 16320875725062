import { PropsWithChildren } from "react";
import { FieldGroup } from "src/components/form/FieldWrapper";
import { FieldValueInputProps } from "./FieldValue";
import { FieldValueInputLabel } from "./FieldValueInputLabel";

export const FieldValueInputWrapper: React.FC<
  FieldValueInputProps & PropsWithChildren
> = ({ children, field, unused, hideLabel = false }) => {
  return (
    <FieldGroup>
      {!hideLabel && <FieldValueInputLabel field={field} unused={unused} />}

      {children}
    </FieldGroup>
  );
};
