import { Button } from "@ream/ui";
import {
  Image,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "react-bootstrap";
import ReactPlayer from "react-player";
import { useAuth } from "src/state/AuthContext";
import { useTranslations } from "src/state/TranslationsContext";
import { useUpdateCurrentUser } from "src/util/api/usersApi";

export const FtueModal: React.FC<{
  show?: boolean;
  onConfirm: () => void;
  onClose: () => void;
}> = ({ show = false, onConfirm, onClose }) => {
  const { user } = useAuth();
  const update = useUpdateCurrentUser();
  const { t } = useTranslations();

  const handleClose = () => {
    update.mutate({ data: { ftueComplete: true } });
    onClose?.();
  };

  const handleConfirm = () => {
    handleClose();
    onConfirm?.();
  };

  return (
    <Modal
      show={show}
      size="lg"
      centered
      onHide={handleClose}
      backdrop="static"
      keyboard={false}
    >
      <ModalHeader closeButton>
        {t("client.ftue.intro_modal.title", {
          name: user?.firstName ?? "there",
        })}
      </ModalHeader>

      <ModalBody className="p-0">
        <ReactPlayer
          light={
            <Image
              src="/img/welcome.png"
              className="img-fluid"
              alt="Welcome to Ream"
            />
          }
          url="/img/welcome.mov"
          autoPlay
          playing
          width="100%"
          height="auto"
          controls
        />
      </ModalBody>

      <ModalBody className="p-5">
        <p className="lead">{t("client.ftue.intro_modal.copy")}</p>
      </ModalBody>

      <ModalFooter>
        <Button onClick={handleClose} variant="secondary">
          {t("client.ftue.intro_modal.close")}
        </Button>

        <Button onClick={handleConfirm}>
          {t("client.ftue.intro_modal.confirm")}
        </Button>
      </ModalFooter>
    </Modal>
  );
};
